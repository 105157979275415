exports.components = {
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-about-community-resources-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/about/community-resources.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-about-community-resources-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-about-contributing-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/about/contributing.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-about-contributing-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-about-introduction-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/about/introduction.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-about-introduction-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-basics-default-assets-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/basics/default-assets.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-basics-default-assets-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-basics-pack-structure-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/basics/pack-structure.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-basics-pack-structure-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-basics-predicates-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/basics/predicates.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-basics-predicates-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-basics-understanding-json-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/basics/understanding-json.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-basics-understanding-json-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-bedrock-equivalencies-resource-pack-conversion-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/bedrock-equivalencies/resource-pack-conversion.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-bedrock-equivalencies-resource-pack-conversion-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-blockbench-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/external-programs/blockbench.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-blockbench-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-cubik-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/external-programs/cubik.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-cubik-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-gimp-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/external-programs/gimp.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-gimp-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-github-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/external-programs/github.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-github-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-imagemagick-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/external-programs/imagemagick.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-imagemagick-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-jq-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/external-programs/jq.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-jq-mdx" */),
  "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-visual-studio-code-mdx": () => import("./../../../src/templates/doc.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/docs/external-programs/visual-studio-code.mdx" /* webpackChunkName: "component---src-templates-doc-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-docs-external-programs-visual-studio-code-mdx" */),
  "component---src-templates-page-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-404-mdx": () => import("./../../../src/templates/page.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/404.mdx" /* webpackChunkName: "component---src-templates-page-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-404-mdx" */),
  "component---src-templates-page-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-index-mdx": () => import("./../../../src/templates/page.ts?__contentFilePath=/home/runner/work/mcjava-wiki/mcjava-wiki/pages/index.mdx" /* webpackChunkName: "component---src-templates-page-ts-content-file-path-home-runner-work-mcjava-wiki-mcjava-wiki-pages-index-mdx" */)
}

